<template>
  <div>
    <el-dialog
        :title="title"
        :visible.sync="isOpenModal"
        :close-on-click-modal="false"
        width="500px"
        @closed="closeModal">
      <div class="qrcode_info" ref="download" v-loading="!text">
        <vue-qr :text="text" :size="200" :margin="0" />
      </div>
      <span slot="footer" class="flex_con">
        <ch-button @click="closeModal" type="cancel">取消</ch-button>
        <ch-button  @click="handleDownload"  type="ok" :loading="isPrintLoading">下载二维码</ch-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import vueQr from "vue-qr"
import html2canvas from "html2canvas";

export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  components: { vueQr },
  data(){
    return{
      isOpenModal:false,
      title:'',
      text:'',
      isPrintLoading:false,
    }
  },
  methods:{
    openCode(id) {
      this.title = '商品二维码'
      this.isOpenModal = true
      this.getCode(id)
    },
    getCode(id){
      this.$curl.get('/hm/goods/getGoodsWxQrcodePath').then((res)=>{
        this.text = res.data + '?id=' + id
      })
    },
    handleDownload() {
      this.isPrintLoading = true
      setTimeout(() => {
        html2canvas(this.$refs.download, {
          useCORS: true,
          type: 'png',
          scale:3,
        }).then((canvas) => {
          const src  = canvas.toDataURL('image/jpeg', 1.0);
          let image = new Image()
          image.src = src
          let url = image.src.replace(/^data:image\/[^;]/, 'data:application/octet-stream')//输出类型
          let a = document.createElement('a');//随便创建一个元素
          a.download = `${this.title}.png`// 设置下载的文件名，默认是'下载'
          a.href = url
          document.body.appendChild(a)
          a.click()
          a.remove() // 下载之后把创建的元素删除
          this.isPrintLoading = false
        }).catch(() => this.isPrintLoading = false )
      },100)

    },
    // 关闭
    closeModal() {
      this.text = ''
      this.isOpenModal = false
    },
  }
}
</script>

<style lang="scss" scoped>
.qrcode_info{
  text-align: center;
  padding: 20px;
  .content{
    margin-top: 15px;
  }
}
</style>